import ContentWrapper from 'components/ContentWrapper'
import Logo from 'components/Logo'
import Layout from 'components/Layout'
import styled from 'styled-components'
import { Flex, Heading, Box } from 'rebass/styled-components'

const Wrapper = styled(Flex)`
  height: 100vh;
  color: ${(props) => props.theme.colors.white};
`

const Subtitle = styled(Heading)`
  letter-spacing: ${(props) => props.theme.letterSpacing};
  text-transform: uppercase;
  font-weight: 100;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
`
// TODO: The compass would be perfect here, but it's not componentized yet
const NotFound = () => (
  <Layout>
    <ContentWrapper $background>
      <Wrapper alignItems='center'>
        <Flex p={4} flexDirection='column' alignItems='center' width={1}>
          <Logo icon size='90px' loading />
          <Box width={[1, '80%']} mt={3}>
            <Subtitle is='h5'>The requested page is not here.</Subtitle>
          </Box>
        </Flex>
      </Wrapper>
    </ContentWrapper>
  </Layout>
)

// Replace 2nd arg with mapStateToProps when needing to connect to redux
export default NotFound
